import Splide from "@splidejs/splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import { DotLottie } from "@lottiefiles/dotlottie-web";
let lottie_list = {};


export function setupSlider(){
	var gallerySlider = document.querySelectorAll(".client-slider");
	var reverseFlag = false;
	var speed = 1;
	if(gallerySlider){
		gallerySlider.forEach(slider => {

			if (reverseFlag){
				speed = -1;
			}
			else{
				speed = 1;
			}
			reverseFlag = !reverseFlag;
			new Splide( slider, {
				type : "loop",
				rewind: false,
				drag   : "free",
				focus  : "center",
				pagination: false,
				arrows    : false,

				gap: "1.5rem",
				padding: { left: "30%", right: "30%" },
				perPage: 1,
				autoScroll: {
					speed: speed,
				},
				// clones:6
			}).mount({ AutoScroll });
		});

	}
}
export function scrollToAnchor(anchorId) {
	var main = document.getElementsByTagName("main");
	var anchor = document.getElementById(anchorId);

	if (main && anchor) {
		anchor.scrollIntoView({ behavior: "smooth", block: "start" });
	}
}
export function setupLottie(){
	const lottie_canvas_list = document.querySelectorAll(".dotlottie-canvas");
	lottie_canvas_list.forEach(canvas => {
		const lottie = new DotLottie({
			autoplay: false,
			loop: true,
			canvas: canvas,
			src: canvas.dataset.src
		});

		const lottie_id = canvas.id.split("-")[0];
		lottie_list[lottie_id] = lottie;
	});
	
}

export function scrollEvents(){
	reverseScroll();
	playLottie();
	keoScroll();
	appearScroll("#client-section");
	appearScroll("#brand-section", "#mobile-logo");
	playLottieifAppeared();
}
export function playLottieifAppeared(){
	
	const sections = document.querySelectorAll(".play-if-appeared");
	const win_height = window.innerHeight;
	const appear_height = win_height / 2;

	sections.forEach(section => {
		if (section.getBoundingClientRect().top < appear_height){

			if(section.getBoundingClientRect().bottom < appear_height){
				if(lottie_list[section.id].isPlaying == true){
					lottie_list[section.id].pause();
				}
			}else{
				if(lottie_list[section.id].isPlaying == false){
					lottie_list[section.id].play();
				}
			}
		}
		else{
			if(lottie_list[section.id].isPlaying == true){
				lottie_list[section.id].pause();
			}
		}
	});
}


export function appearScroll(selector, other_target=null)
{
	const element = document.querySelector(selector);
	const other_target_element = document.querySelector(other_target);
	const win_height = window.innerHeight;
	const appear_height = win_height / 2;
	element.getBoundingClientRect().top;

	if (element.getBoundingClientRect().top < appear_height){
		if(element.getBoundingClientRect().bottom < appear_height){
			if(element.classList.contains("appear")){
				element.classList.remove("appear");
			}
			if (other_target_element && other_target_element.classList.contains("appear")){
				other_target_element.classList.remove("appear");
			}
		}else{
			if(!element.classList.contains("appear")){
				element.classList.add("appear");
			}
			if (other_target_element && !other_target_element.classList.contains("appear")){
				other_target_element.classList.add("appear");
			}
		}
	}
	else{
		if(element.classList.contains("appear")){
			element.classList.remove("appear");
		}
		if (other_target_element && other_target_element.classList.contains("appear")){
			other_target_element.classList.remove("appear");
		}
	}
}

export function playLottie(){
	const sections = document.querySelectorAll("#reverseScroll section.has-animation");
	const win_height = window.innerHeight;
	sections.forEach(section => {
		if(section.getBoundingClientRect().top > (win_height/2) * -1 &&  section.getBoundingClientRect().bottom < (win_height * 1.25)){
			if(lottie_list[section.id].isPlaying == false){
				lottie_list[section.id].play();
			}
		
		}
		else{
			if(lottie_list[section.id].isPlaying == true){
				lottie_list[section.id].pause();
			}
		}
		
	});


}
export function reverseScroll(){

	const panelCon = document.querySelector("#reverseScroll");

	const startScrollElement = document.querySelector(".start-scroll");
	const startScroll = startScrollElement.getBoundingClientRect().top;
	if (startScroll < 0){
		panelCon.style.bottom = startScroll + "px";
	}
	else{
		panelCon.style.bottom = 0;
	}
}
export function keoScroll(){

	const triangle = document.querySelector("#keo-triangle");
	const win_height = window.innerHeight;
	const turnFlag1 = document.querySelector(".turn-1-flag");
	const turnFlag2 = document.querySelector(".turn-2-flag");
	const turnFlag3 = document.querySelector(".turn-3-flag");
	const keo_triangle_small = document.querySelector("#keo-triangle-corner");
	if(turnFlag1.getBoundingClientRect().top < (win_height * .75) * -1){
		if(!triangle.classList.contains("turn-1")){
			triangle.classList.add("turn-1");
		}
	}
	else{
		if(triangle.classList.contains("turn-1")){
			triangle.classList.remove("turn-1");
		}
	}

	if(turnFlag2.getBoundingClientRect().top < (win_height * .75) * -1){
		if(!triangle.classList.contains("turn-2")){
			triangle.classList.add("turn-2");
		}
	}
	else{
		if(triangle.classList.contains("turn-2")){
			triangle.classList.remove("turn-2");
		}
	}

	if(turnFlag3.getBoundingClientRect().top < (win_height * .75) * -1){
		if(keo_triangle_small.classList.contains("hide")){
			keo_triangle_small.classList.remove("hide");
		}
	}
	else{
		if(!keo_triangle_small.classList.contains("hide")){
			keo_triangle_small.classList.add("hide");
		}
	}
	
}