import "lazysizes";
import { workcards } from "./workcards.js";
import VanillaTilt from "vanilla-tilt";

var html_classes = document.documentElement.classList;
html_classes.remove("no-js");
html_classes.add("js");

import { FloatLabels } from "@keodesign/float-labels";
import { ProportionalImageNormalisation } from "@keodesign/proportional-image-normalisation";
import { pipedriveFormSubmit} from "./pipedrive.js";
import { setupSlider, setupLottie, scrollEvents, scrollToAnchor} from "./landing.js";

$().ready(function(){
	const js_tilt_el = document.querySelectorAll(".js-tilt");
	if (js_tilt_el){
		VanillaTilt.init(js_tilt_el,{
			"full-page-listening": true
		});
	}

	var btns = $(".services-menu>ul>li");
	btns.removeClass("active");
	$(".infield").inFieldLabels();
	$("form").each(function(){
		$(this).validate();
	});
	setupSlideMenu();
	loadMore();
	selectVideo();
	serviceButtons();
	if ($(".grid")) {
		$(".grid").masonry({
			itemSelector: ".grid-item",
			columnWidth: ".grid-sizer",
			percentPosition: true
		});
	}
	wave();
	casestudy();
	officeBanner();
	beerOclock();
	workcards();
	faqclicker();
	landingPage();
	spoilers();
	//Prevent navigate on opening "What We Do" menu.
	$(".mainmenu a[href='/#']").click(function(e){
		e.preventDefault();
	});
	getFeedback();
	// External links analytics
	var load = false;
	$("a[href^='http']").on("click", function(e){
		if(typeof ga != "undefined"){
			// intercept the click - unless it opens in a new tab
			if ($(this).attr("target") != "_blank"){
				e.preventDefault();
				load = $(this).attr("href");
			}
			if ($(this).hasClass("site-link")){
				// so we can make social clicks a conversion
				// they can be grouped together
				ga("send", {
					hitType: "event",
					eventCategory: "click",
					eventAction: $(this).attr("href"),
					eventLabel: "Social Links",
					hitCallback: function(){
						// if set - open link after event logged
						if (load){
							window.location = load;
						}
					}
				});
			}
			else {
				ga("send", {
					hitType: "event",
					eventCategory: "click",
					eventAction: $(this).attr("href"),
					eventLabel: "External",
					hitCallback: function(){
						// if set - open link after event logged
						if (load){
							window.location = load;
						}
					}
				});
			}
		}
	});
	// phone or email links analytics
	$("a[href^='tel']").on("click", function(e){
		// intercept the click - unless it opens in a new tab
		if ($(this).attr("target") != "_blank"){
			e.preventDefault();
			load = $(this).attr("href");
		}
		if(typeof ga != "undefined"){
			ga("send", {
				hitType: "event",
				eventCategory: "click",
				eventAction: $(this).attr("href"),
				eventLabel: "Call",
				hitCallback: function(){
					// if set - open link after event logged
					if (load){
						window.location = load;
					}
				}
			});
		}
	});
	$("a[href^='mailto']").on("click", function(e){
		// intercept the click - unless it opens in a new tab
		if ($(this).attr("target") != "_blank"){
			e.preventDefault();
			load = $(this).attr("href");
		}
		if(typeof ga != "undefined"){
			ga("send", {
				hitType: "event",
				eventCategory: "click",
				eventAction: $(this).attr("href"),
				eventLabel: "Email",
				hitCallback: function(){
					// if set - open link after event logged
					if (load){
						window.location = load;
					}
				}
			});
		}
	});
	checkSmokeBanner();

	// KEO 2024 Landing page
	if(document.querySelector(".t-landing")){
		new FloatLabels(".js-float-wrap");
		setupLottie();
		scrollEvents();
		const main = document.querySelector("main");
		main.addEventListener("scroll", scrollEvents);
		var hash = window.location.hash;
	
		if (hash) {
			// Remove the leading '#' from the hash
			var anchorId = hash.substring(1);
			scrollToAnchor(anchorId);
		}
		ProportionalImageNormalisation(".client-section img", 100, 0.525);
		setupSlider();
		if(document.querySelector("#form-section")){
			pipedriveFormSubmit();
		}
	}
});

function getFeedback(){
	$("fieldset").on("click", function() {
		var $this = $(this);
		if ($this.find("input:radio.high").is(":checked")) {
			$this.removeClass("hide");
			$this.find("span.textarea label").html("Great! Tell us what made this week a 10!");
		} else if ($this.find("input:radio.low").is(":checked")) {
			$this.removeClass("hide");
			$this.find("span.textarea label").html("Uh oh. Let us know what went wrong.");
		} else {
			$this.addClass("hide");
		}
	});
	//Manage staff image icons
	var name_selects = document.querySelectorAll(".staff-image");
	Array.from(name_selects).forEach(name_select => {
		if (name_select) {
			var staff_img = document.getElementById(name_select.dataset.imageTarget);
			var updateStaffImage = function(){
				var selected_option = name_select.options[name_select.selectedIndex];
				if (selected_option.dataset["image"]) {
					staff_img.src = selected_option.dataset["image"];
					staff_img.style.display = "inline";
				} else {
					staff_img.src = "";
					staff_img.style.display = "none";
				}
			};
			name_select.addEventListener("change", updateStaffImage);
			updateStaffImage();
		}
	});
}

export function faqclicker(){
	$(".question").on("click", function(){
		var btns = $(".question");
		var btnid = $(this).attr("id");
		btnid = btnid.split("-");
		var divHght = 0;
		if($(this).hasClass("active")){
			$(".answer").css({"height": "0"});
			btns.removeClass("active");
		} else {
			$(".answer").css({"height": "0"});
			divHght = $("#a-"+btnid[1]).find("div");
			divHght = divHght.height()+50;
			$("#a-"+btnid[1]).css({"height": divHght});
			btns.removeClass("active");
			$(this).addClass("active");
		}
	});
}


function hasMore(the_url, index, button){
	$.ajax({
		url: the_url,
		data:{format:"json", page:index, count:4}
	}).done(function(data){
		if (data.content_items && data.content_items.length == 0) {
			button.hide();
		}
	});
}



export function wave(){
	$("#toplogo2").addClass("animate");
	setTimeout(function(){
		$("#toplogo2").removeClass("animate");
	},1000);
}
function serviceButtons(){
	var btns = $(".services-menu>ul>li");
	var divs = $(".thingy");

	function activateServiceMenu($a) {
		if($a.length == 0){
			return;
		}
		btns.removeClass("active");
		$a.parent().addClass("active");
		var id = $a.attr("href").split("#")[1];
		var newElem = $("#"+id);
		closeDiv(newElem);
	}

	function closeDiv(newEl){
		divs.css({opacity: "0"});
		setTimeout(function(){divs.hide();}, 250);
		setTimeout(function(){
			$(newEl).css({display: "inline-block", opacity:"1"});
			var newPos = 0;
			newPos = newEl.offset().top - $("#stickynav").height();
			$("html, body").animate({scrollTop: newPos}, 300);
		}, 251);
		if (typeof ga == "function") {
			// ga('send', 'event', 'Enrol', 'open', $(".enroll-now").data('course-name'));
			ga("send", "pageview", "/"+newEl.attr("id"));
		}
	}

	$(".services-menu .depth-0>a").click(function(e){
		e.preventDefault();
		activateServiceMenu($(this));
	});

	if(document.location.hash != "") {
		activateServiceMenu(btns.find("a[href$='" + document.location.hash + "']"));
	} else if ((location.pathname.split("/")[1] == "our-expertise") || (location.pathname.split("/")[1] == "your-business")) {
		activateServiceMenu(btns.first().find("a:first-child()"));
	}

	$(".mainmenu a[href*='#'], .slide-menu a[href*='#']").on("click", function(e){
		var id = $(this).attr("href").split("#")[1];
		var targetMenuItem = $(".services-menu .depth-0>a[href$=\"#" + id + "\"]");
		if(targetMenuItem.length > 0){
			e.preventDefault();
			activateServiceMenu(targetMenuItem);
			if ($.contains($(".slide-menu").get(0),this)) {
				$("body").removeClass("slide-menu-active");
			}
		}
	});
}



function setupSlideMenu(){

	$("#mobile-menu, .slide-menu h3").click(function(){
		$("body").toggleClass("slide-menu-active");
	});

	$(".slide-menu").on("click",".expand",function(e) {
		e.stopPropagation();
		$(this).closest("li").toggleClass("expand-active");
	});

	$(".slide-menu li").each(function(index, element) {
		var $el = $(element);
		if ($el.find("ul").length > 0) {
			var expander = $("<span class=\"expand\"><i class=\"nav-arrow\"></i></span>");
			$el.append(expander);
			expander.click(function(e) {
				e.stopPropagation();
				$el.toggleClass("expand-active");
			});
		}
	});
}



var pageIndex=2;
function loadMore() {
	var working = false;
	$(".load-more-btn").click(function(){
		var button = $(this);
		if(working == false) {
			working = true;
			$(".blog-page-content").addClass("active");
			$.ajax({
				url:"/content/news/",
				data:{format:"json", page:pageIndex, count:4}
			}).done(function(data){
				if (data.content_items){
					pageIndex++;
					$.each(data.content_items, function(index, item){
						$(".blog-page-content").append(
							"<a href=\""+item.canonical_url+"\">"+
                                "<div class=\"blog-content-preview\" style=\"background-image:url('"+item.graphic_url+"')\">"+
                                    "<div class=\"blog-content-body\">"+
                                        "<h3>"+item.title+"</h3>"+
                                        "<p>VIEW</p>"+
                                    "</div>"+
                                "</div>"+
                            "</a>");
					});
					if (data.content_items.length < 4){
						button.hide();
					} else { // check next page
						hasMore("/content/news/", pageIndex, button);
					}
				} else {
					console.log("content_items = "+ data.content_items);
				}
				working = false;
				$(".blog-page-content").removeClass("active");
			}).fail(function(jqXHR, textStatus, errorThrown){
				console.log("Ajax error. Status: "+ textStatus +" error: "+ errorThrown);
				working = false;
			}).always(setTimeout(function(){$(".blog-page-content").removeClass("active");},5000));
		} else return;
	});
}

export function casestudy(){
	var buttons = $(".case-study-topics").find("button");
	var id = "";
	var divs = $(".case-study-content");
	buttons.click(function(){
		buttons.removeClass("active");
		id = $(this).attr("id");
		id = id.split("btn")[0];
		$(this).addClass("active");
		divs.css({opacity: "0"});
		var newPos = 0;
		newPos = $(".case-study").offset().top - $("#stickynav").height();
		$([document.documentElement, document.body]).animate({scrollTop: newPos}, 300);
		setTimeout(function(){divs.hide();}, 250);
		setTimeout(function(){$("#"+id+"-content").css({display: "inline-block"});}, 251);
		setTimeout(function(){$("#"+id+"-content").css({opacity: "1"});}, 252);
	});
}

function topFunction() {
	var $body = $("html,body");
	$body.animate({
		scrollTop: $body.offset().top},
	"slow");
}
if (document.getElementById ("myBtn"))
	document.getElementById ("myBtn").addEventListener ("click", topFunction, false);

function selectVideo(){
	var videoElem = $(".video-btn");
	videoElem.click(function(){
		displayVideo($(this));
		videoElem.removeClass("active");
		$(this).addClass("active");
	});
}



function displayVideo(element) {
	$("#ytplayer").html("");
	var iframe_url = "https://www.youtube.com/embed/" + element[0].id + "?rel=0" ;
	if (typeof ga == "function") {
		// ga('send', 'event', 'Enrol', 'open', $(".enroll-now").data('course-name'));
		ga("send", "pageview", "/"+element[0].innerText+"-video");
	}
	var videoTitle = element[0].textContent;
	var videoDescription = element.data("description");
	$("#ytplayer").append("<div class=\"vlog-video\"><iframe src=\"" + iframe_url + "\" class=\"yt-iframe\"></iframe><h2>" + videoTitle + "</h2><p>" + videoDescription + "</p></div>");
	$(".star").removeClass("active");
	$(".star.vlog-"+element.data("id")).addClass("active");
	$("html,body").animate({
		scrollTop: $("#ytplayer").offset().top},
	"slow");
}
var rotInt;
export function officeBanner(){
	var bann = $(".banners-office .banner");
	if (bann.length == 0){
		$(".banners-office").hide();
	} else if (bann.length == 1) {
		$(".banners-office .banner").css({left:"0", opacity:"1"});
	} else if (bann.length == 2) {
		$(".banner.active").addClass("on");
		bann.eq(1).addClass("right");

		var moveBanners = function(){
			var currB = $(".banner.active");
			var nB = $(".banner:not(.active)");
			currB.addClass("left").removeClass("on").removeClass("active");
			nB.addClass("on").addClass("active").removeClass("right");
			setTimeout(function(){currB.addClass("right").removeClass("left");}, 2000);
		};
		if (rotInt) {
			clearInterval(rotInt);
		}
		rotInt = setInterval(function(){moveBanners();}, 5000);
	} else {
		var bannIndex = 0;

		var setBannClasses = function(){
			var first = $(".banner.left");
			first.removeClass("left");
			bann.eq((bannIndex-1) % bann.length).addClass("left").removeClass("on");
			bann.eq((bannIndex) % bann.length).addClass("on").removeClass("right");
			bann.eq((bannIndex+1) % bann.length).addClass("right");
			// Trigger repaint by interrogating browser layout
			$(".banner.on").width();
		};
		setBannClasses();
		var slideBann = function(){
			if (!$("#banners").data("animating")){
				$("#banners").data("animating",true);
				$(".banner-bar li.active").removeClass("active");
				bannIndex++;
				setBannClasses();
				if (bannIndex == $(".banner-bar li").length) bannIndex = 0;
				$(".banner-bar li").eq(bannIndex).addClass("active");
			}
		};
		if (rotInt) {
			clearInterval(rotInt);
		}
		rotInt = setInterval(function(){slideBann(1);},5000);
	}
}
export function beerOclock() {
	var $el = $(".timertimer");
	var newEl = $el.clone();
	newEl.insertAfter(".block-image:first");
	$el.remove();
}


var timer = null;
var visible = [];
function slideVisible() {
	var screen_bottom = $(window).scrollTop()+$(window).height()*1.95;
	$(".slide-img:not(\".isseen\")").each(function(index,el){
		var $el = $(el);
		if ($el.offset().top < screen_bottom){
			$el.addClass("isseen");
			visible.push($el);
			if(!timer) {
				decue();
			}
		}
	});
}
window.onscroll = slideVisible;
slideVisible();
var body = $("body");
var $window = $(window);
var logo2 = $("#toplogo2");
if (!document.body.classList.contains("t-home")) {
	$window.scroll(function() {
		var scroll = $window.scrollTop();
		if(scroll > 0) {
			body.addClass("stick-nav");
			logo2.addClass("spin").removeClass("backspin");

		} else {
			body.removeClass("stick-nav");
			logo2.removeClass("spin").addClass("backspin");
		}
	});
}
function decue(){
	if(visible.length > 0) {
		var item = visible.shift();
		item.addClass("isvisible");
		timer = setTimeout(decue, 500);
	} else {
		timer = null;
	}
}
export function landingPage() {
	function displaySubPopUp(){
		$("#landing-page").show();
		setTimeout(function(){$("#landing-page").css({opacity:"1"});}, 100);
	}
	$("#get-a-cooler").on("click", function(e){
		e.preventDefault();
		displaySubPopUp();
	});
	$(".skip").on("click", function(){
		$("#landing-page").css({opacity:"0"});
		setTimeout(function(){$("#landing-page").hide();}, 500);
	});
	$("#landing-page").on("click", function(e){
		if(e.target !== this){
			return;
		} else {
			$("#landing-page").css({opacity:"0"});
			setTimeout(function(){$("#landing-page").hide();}, 500);
		}
	});
}

export function spoilers(){
	$(".spoiler").click(function(e){
		e.preventDefault();
		var $this = $(this);
		if (!$this.hasClass("open")) {
			$this.addClass("open");
			window.ga("send", "event", "spoiler", "reveal", $this.attr("href"));
		} else {
			var navigated = false;
			var navigate = function(){
				if (!navigated) {
					navigated = true;
					document.location = $this.attr("href");
				}
			};
			// Creates a timeout to navigate after one second.
			setTimeout(navigate, 1000);
			window.ga("send", "event", "spoiler", "click", $this.attr("href"), {"hitCallback":navigate});
		}
	});
}
$().ready(function(){
	$("html").addClass("doc-ready");
});
$(window).on("load",function(){
	$("html").addClass("doc-loaded");
});

//add style attribute with background image when lazyload event fired
document.addEventListener("lazybeforeunveil", function(e){
	var container = e.target;
	var bgUrl = container.dataset.bgUrl;
	if (bgUrl) {
		container.style.backgroundImage = "url('" + bgUrl + "')";
		delete container.dataset.bgUrl;
	}
});

function webGlSupported() {
	try {
		var canvas = document.createElement("canvas");
		return !!window.WebGLRenderingContext && (canvas.getContext("webgl") || canvas.getContext("experimental-webgl"));
	} catch (e) {
		return false;
	}
}

function checkSmokeBanner(){
	if (document.querySelector(".banner-video-container")) {
		var logo = document.querySelector(".video-logo");
		logo.style.display = "none";
		var video = document.querySelector(".banner-video");
		if (video)
			video.pause();
		/* Check for webGL Support */
		var webgl = webGlSupported();
		if (webgl) {
			if (video) {
				video.remove();
			}
			import(/* webpackChunkName: "smoke" */ "./smoke-banner.js");
		}else{
			/* fall back to video banner */
			if (!video) {
				document.querySelector(".banner-video-container").insertAdjacentHTML("afterbegin", `
					<video class="banner-video" autoplay muted>
					<source src="/app/img/ink-bg.mp4" type="video/mp4">
					Your browser does not support the video tag.
				</video>`);
			} else {
				video.play();
			}
			logo.style.display = "block";
			setTimeout(function(){
				video.classList.add("zoom");
			}, 10000);
		}
	}
}
