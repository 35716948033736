var thisIndex = 2;
export function workcards(){
	var working = false;
	var container = document.querySelector(".our-work-section .load-btn-container");
	if (!container) return;
	var data = JSON.parse(container.dataset.more);
	$(".load-cards").click(function(){
		var button = $(this);
		if (working == false) {
			working = true;
			var items = data.slice(thisIndex*4, ((thisIndex+1)*4));
			$(".the-work-we-do").addClass("active");            
            
			$.each(items, function(index, item){
				$(".the-work-we-do").append(
					"<a href=\""+item.canonical_url+"\">"+
                        "<div class=\"work-card\" style=\"background-image:url('"+item.graphic_url+"')\">"+
                            "<div class=\"card-content-body\" style=\"background-color:"+item.custom_json.color+"\">"+
                                "<h3>"+item.title+"</h3>"+
                                "<p class=\"viewcasestudy\">VIEW<img src=\"/app/img/right.svg\" alt=\"\"></p>"+
                            "</div>"+
                        "</div>"+
                    "</a>");
			});
			thisIndex++;
			if (data.length/4 < thisIndex){
				button.hide();
			}
			$(".the-work-we-do").removeClass("active");
			working = false;
		} else return;
	});
}