const KEO_PIPEDRIVE_OWNER_ID = 11227883;

const requestData = async (url, data) =>{
	const response = await fetch("/pipedrive-send" + url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(data),
	});
	const res = await response.json();
	if (res.success && res.data) {
		return res.data;
	}
	return null;
};

const postOrganization = async (company) => {
	const orgRequest = {
		name: company,
		owner_id: KEO_PIPEDRIVE_OWNER_ID,
		add_time: new Date(),
	};
	return await requestData("/organizations", orgRequest);
};

const postPerson = async (orgId, fullname, phone, email) => {
	const personRequest = {
		name: fullname,
		org_id: String(orgId),
		owner_id: KEO_PIPEDRIVE_OWNER_ID,
		email: [
			{
				value: email,
				primary: "true",
				label: "main",
			},
		],
		phone: [
			{
				value: phone,
				primary: "true",
				label: "phone",
			},
		],
		visible_to: "3",
		add_time: new Date(),
	};

	return await requestData("/persons", personRequest);
};

const postLead = async (person, org) => {
	const leadRequest = {
		title: `KEO Landing Page - ${person.name} from ${org.name}`,
		person_id: person.id,
		organization_id: org.id,
		owner_id: KEO_PIPEDRIVE_OWNER_ID,
		visible_to: "3",
	};
	return await requestData("/leads", leadRequest);
};

const postNotes = async (content, lead) => {
	const notesRequest = {
		content,
		lead_id: lead.id,
		pinned_to_lead_flag: 1,
	};
	return await requestData("/notes", notesRequest);
};

const submitForm = async (
	fullname,
	phone,
	email,
	company,
	message,
	flavour
) => {
	try {
		const org = await postOrganization(company);
		if (!org) {
			console.log("Something went wrong during organization request");
			return;
		}
		const person = await postPerson(org.id, fullname, phone, email);
		if (!person) {
			console.log("Something went wrong during person request");
			return;
		}
		const lead = await postLead(person, org);
		if (!lead) {
			console.log("Something went wrong during lead request");
			return;
		}
		if (message) {
			const messageRes = await postNotes(`Message: ${message}`, lead);
			if (!messageRes) {
				console.log("Something went wrong during notes request (Message)");
			}
		}
		if (flavour) {
			const flavourRes = await postNotes(`Ice Cream flavour: ${flavour}`, lead);
			if (!flavourRes) {
				console.log("Something went wrong during notes request (Flavour)");
			}
		}
	} catch (error) {
		console.error("Error submitting form:", error);
	}
};

export const pipedriveFormSubmit = () => {
	const form = document.querySelector("#form-section form");
	const submit_btn = form.querySelector(":scope #submit");


	if (submit_btn){
		submit_btn.addEventListener("click", async (e) => {
	
			let isValid = true;

			for (let element of form.elements) {
				// Check if the element is an input and is invalid
				if (element instanceof HTMLInputElement && !element.checkValidity()) {
					isValid = false;
					// Report the validation message
					element.reportValidity();
					// Optionally, you can break after finding the first invalid input
					// break;
				}
			}
			if (isValid){
				const fullname = form.querySelector(":scope #full_name").value;
				const phone = form.querySelector(":scope #phone").value;
				const email = form.querySelector(":scope #email").value;
				const company = form.querySelector(":scope #company").value;
				const message = form.querySelector(":scope #message").value;
				const flavour = form.querySelector(":scope #flavour").value;
				submit_btn.innerHTML = "Sending";
				submit_btn.classList.add("sending");

				await submitForm(fullname, phone, email, company, message, flavour);
				const url = new URL(window.location);

				// Set or update the URL parameter
				url.searchParams.set("form_emailed", "true");
				window.location.href = url + "#form-result";
			}
			

			e.preventDefault();
			e.stopPropagation();

		});
	}
	
};
